<template>
  <div class="bg-white rounded-5 p-24 flex justify-between items-center">
    <div class="flex items-center">
      <AppIcon name="info-circle" size="24" class="mr-10 text-liquid-gold" />
      <p class="text-md text-grey-fp-70 pr-10">
        Registration takes place on a third party website. When you return to 
        CEB Connects, you can also mark this activity as attending.
      </p>
    </div>
    <AppIcon name="close" class="text-grey-fp-70 cursor-pointer" @click="$emit('close')" />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  import AppIcon from '@/components/stateless/AppIcon.vue';

  export default defineComponent({
    name: 'RegistrationBanner',

    components: { AppIcon },

    emits: ['close']
  });
</script>

<style lang="scss" scoped>

</style>