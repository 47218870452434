<template>
  <div class="overflow-auto">
    <template
      v-for="(row, i) in dataset"
      :key="`${i}-mobile`"
      class="mb-20"
    >
      <div
        class="px-14 py-18 my-8 rounded-5 bg-white"
        data-test="table-card"
        :class="[cardClassChecker(row)]"
      >
        <div>
          <div
            v-for="header of visibleColumns"
            :key="`${header.property}`"
            class="h-34 mb-10"
          >
            <div class="flex items-center overflow-hidden w-full h-full">
              <div
                class="table__headers"
                :class="{ 'w-1/2' : header.label }"
              >
                <div
                  class="cell truncate"
                  data-test="table-mobile-header"
                >
                  <slot :name="`header_${header.property}`">{{ header.label }}</slot>
                </div>
              </div>

              <div
                class="text-sm truncate"
                :class="[{'w-1/2' : header.label }, header.cellClasses]"
              >
                <div class="cell truncate">
                  <slot
                    :name="header.property"
                    :row="row"
                    :rowIndex="i"
                  >
                    <AppTruncatedTooltip :content="generateValue(row, header.property)" />
                  </slot>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="$slots.actions" class="pt-18 border-t border-grey-fp-10">
          <slot
            name="actions"
            :row="row"
          />
        </div>
      </div>

      <div
        v-if="i !== dataset.length - 1"
        class="h-5"
      />
    </template>

    <slot name="pagination" />
  </div>
</template>
<script lang="ts">
  import { computed, defineComponent, PropType } from 'vue';

  import AppTruncatedTooltip from '@/components/stateless/AppTruncatedTooltip.vue';

  import { ITableHeaders, TIndexedObject } from '@/types';
  import { getNestedProp } from '@/core/helper-functions';

  export default defineComponent({
    name: 'AppTableCards',

    components: { AppTruncatedTooltip },

    props: {

      headers: {
        type: Array as PropType<ITableHeaders[]>,
        required: true
      },

      dataset: {
        type: Array as PropType<TIndexedObject[]>,
        required: true
      },

      theme: {
        type: String,
        default: 'primary',
        validator: (value: string) => ['primary', 'blue', 'orange', 'green'].indexOf(value) !== -1
      },

      type: {
        type: String,
        default: 'family',
        validator: (value: string) => ['family', 'admin'].indexOf(value) !== -1
      },

      showActions: {
        type: Boolean,
        default: false
      },

      cardClassChecker: {
        type: Function,
        default: () => ''
      }
    },

    emits: ['update:dataset'],

    setup(props) {

      const visibleColumns = computed(() => props.headers.filter((h) => h.show || h.show === undefined));

      function generateValue(obj: TIndexedObject, key: string) {
        const value = getNestedProp(obj, key);

        return Array.isArray(value) ? value.join(', ') : value;
      }

      return {
        visibleColumns,

        generateValue,
      };
    }
  });
</script>
