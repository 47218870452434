
  import { defineComponent, PropType } from 'vue';

  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppTable from '@/components/stateless/AppTable.vue';
  import AppButton from '@/components/stateless/AppButton.vue';
  import AppBadge from '@/components/stateless/AppBadge.vue';
  import AppModal from '@/components/stateless/AppModal.vue';
  import AppTableCards from '@/components/AppTableCards.vue';
  import AppTruncatedTooltip from '@/components/stateless/AppTruncatedTooltip.vue';

  import { IActivity } from '@/types';
  import { REGISTRATION_STATUSES, ACTIVITY_STATUSES } from '@/core/activity-statuses';

  export default defineComponent({
    name: 'RegisteredActivities',

    components: { AppIcon, AppTable, AppTableCards, AppButton, AppBadge, AppModal, AppTruncatedTooltip },

    props: {
      activitiesList: {
        type: Array as PropType<IActivity[]>,
        required: true
      },

      headers: {
        type: Array,
        required: true
      },

      title: {
        type: String,
        default: ''
      },

      openModal: {
        type: Boolean,
        default: false
      },

      externalLink: {
        type: String,
        default: ''
      },

      showTypeIcon: {
        type: Boolean,
        default: true
      }
    },

    emits: [ 'confirm', 'discard', 'browse', 'cancel' ],

    setup() {

      function classChecker ({ registrations }: IActivity) {
        return registrations?.length && registrations[0].status === REGISTRATION_STATUSES.INITIAL_CANCELLED && 'bg-orange-fp-20';
      }

      function showConfirmationActions({ registrations }: IActivity) {
        return registrations?.length && registrations[0].status === REGISTRATION_STATUSES.INITIAL_CANCELLED;
      }

      return { 
        REGISTRATION_STATUSES, 
        ACTIVITY_STATUSES, 

        classChecker,
        showConfirmationActions
      };
    }
  });
