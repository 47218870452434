
  import { computed, defineComponent, PropType } from 'vue';

  import AppTruncatedTooltip from '@/components/stateless/AppTruncatedTooltip.vue';

  import { ITableHeaders, TIndexedObject } from '@/types';
  import { getNestedProp } from '@/core/helper-functions';

  export default defineComponent({
    name: 'AppTableCards',

    components: { AppTruncatedTooltip },

    props: {

      headers: {
        type: Array as PropType<ITableHeaders[]>,
        required: true
      },

      dataset: {
        type: Array as PropType<TIndexedObject[]>,
        required: true
      },

      theme: {
        type: String,
        default: 'primary',
        validator: (value: string) => ['primary', 'blue', 'orange', 'green'].indexOf(value) !== -1
      },

      type: {
        type: String,
        default: 'family',
        validator: (value: string) => ['family', 'admin'].indexOf(value) !== -1
      },

      showActions: {
        type: Boolean,
        default: false
      },

      cardClassChecker: {
        type: Function,
        default: () => ''
      }
    },

    emits: ['update:dataset'],

    setup(props) {

      const visibleColumns = computed(() => props.headers.filter((h) => h.show || h.show === undefined));

      function generateValue(obj: TIndexedObject, key: string) {
        const value = getNestedProp(obj, key);

        return Array.isArray(value) ? value.join(', ') : value;
      }

      return {
        visibleColumns,

        generateValue,
      };
    }
  });
