
  import { defineComponent, PropType } from 'vue';

  import AppTable from '@/components/stateless/AppTable.vue';
  import AppTableCards from '@/components/AppTableCards.vue';
  import AppButton from '@/components/stateless/AppButton.vue';
  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppModal from '@/components/stateless/AppModal.vue';
  import AppTruncatedTooltip from '@/components/stateless/AppTruncatedTooltip.vue';

  import { IActivity } from '@/types';
  import { REGISTRATION_STATUSES, ACTIVITY_STATUSES } from '@/core/activity-statuses';

  export default defineComponent({
    name: 'UpcomingActivities',

    components: { AppTable, AppButton, AppTableCards, AppIcon, AppModal, AppTruncatedTooltip },

    props: {
      activitiesList: {
        type: Array as PropType<IActivity[]>,
        required: true
      },

      headers: {
        type: Array,
        required: true
      },

      title: {
        type: String,
        default: ''
      },

      openModal: {
        type: Boolean,
        default: false
      },

      externalLink: {
        type: String,
        default: ''
      },

      showTypeIcon: {
        type: Boolean,
        default: true
      }
    },

    emits: ['browse', 'register', 'discard', 'confirm'],

    setup() {

      function classChecker(row: IActivity) {
        return row.registrations?.length && row.registrations[0].status === REGISTRATION_STATUSES.INITIAL && 'bg-orange-fp-20';
      }

      function showConfirmationActions({ registrations }: IActivity) {
        return registrations?.length && registrations[0].status === REGISTRATION_STATUSES.INITIAL;
      }

      return {
        REGISTRATION_STATUSES,
        ACTIVITY_STATUSES,

        classChecker,
        showConfirmationActions,
      };
    }

  });
