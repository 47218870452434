<template>
  <div class="bg-transparent md:bg-white rounded-5 p-14 sm:p-20 md:p-25">
    <p v-if="title && !$slots.title" class="hidden md:block text-lucky-orange text-md md:text-1xl">{{ title }}</p>
    <div>
      <slot name="title" />
    </div>

    <div class="md:hidden">
      <!-- M O B I L E   C A R D S -->
      <AppTableCards
        v-if="activitiesList.length"
        :dataset="activitiesList"
        :headers="headers"
        :card-class-checker="classChecker"
        class="md:hidden mobile-table"
      >
        <!-- R O W S -->
        <template #description="{ row }">
          <span class="text-grey-fp-50">{{ row.description }}</span>
        </template>

        <template #startDate="{ row }">
          <div class="flex flex-col">
            <span>
              {{ $filters.date(row.startDate, 'MM/DD/YYYY') }}
            </span>
            <span class="text-grey-fp-50 text-xs">
              {{ $filters.date(row.startDate, 'h:mm a') }}
            </span>
          </div>
        </template>

        <template #endDate="{ row }">
          <div class="flex flex-col">
            <span>
              {{ $filters.date(row.endDate, 'MM/DD/YYYY') }}
            </span>
            <span class="text-grey-fp-50 text-xs">
              {{ $filters.date(row.endDate, 'h:mm a') }}
            </span>
          </div>
        </template>

        <template #actions="{ row }">
          <div
            v-if="showConfirmationActions(row)"
            class="flex w-full items-center"
          >
            <p class="text-grey-fp-70 mr-10">Did you register?</p>
            <AppButton
              type="warning"
              size="mini"
              class="px-20 mr-10"
              @click="$emit('confirm', row.registrations[0].id)"
            >
              Yes
            </AppButton>
            <AppButton
              type="warning"
              size="mini"
              plain
              class="px-20"
              @click="$emit('discard', row.registrations[0].id)"
            >
              No
            </AppButton>
          </div>
          <AppButton
            v-else
            type="warning"
            size="mini"
            class="px-20"
            @click="$emit('register', row)"
          >
            Register
          </AppButton>
        </template>

        <template #pagination>
          <!-- P A G I N A T I O N -->
          <slot name="pagination" />
        </template>
      </AppTableCards>

      <!-- N O  D A T A  S E C T I O N -->
      <template v-else>
        <div v-if="!$slots.noData" class="flex items-center justify-center py-60 text-grey-fp-70">
          There are no activities
        </div>
        <slot name="noData" />
      </template>
    </div>

    <!-- D E S K T O P  T A B L E -->
    <div class="hidden md:flex flex-col">
      <AppTable
        :dataset="activitiesList"
        :headers="headers"
        class="activities-table"
        rowHeight="50px"
        sort-front-side
        :row-class-checker="classChecker"
      >
        <!-- R O W S -->

        <template v-if="showTypeIcon" #name="{ row }">
          <div class="flex justify-center items-center">
            <AppIcon
              size="30"
              :name="row.category.id ? row.category.iconName : 'other'"
              class="mr-12"
            />
            <AppTruncatedTooltip
              placement="bottom-start"
              :content="row.name"
            />
          </div>
        </template>

        <template #startDate="{ row }">
          <div class="flex flex-col">
            <p>
              {{ $filters.date(row.startDate, 'MM/DD/YYYY') }}
            </p>
            <p class="text-grey-fp-50 text-xs">
              {{ $filters.date(row.startDate, 'h:mm a') }}
            </p>
          </div>
        </template>

        <template #endDate="{ row }">
          <div class="flex flex-col">
            <p>
              {{ $filters.date(row.endDate, 'MM/DD/YYYY') }}
            </p>
            <p class="text-grey-fp-50 text-xs">
              {{ $filters.date(row.endDate, 'h:mm a') }}
            </p>
          </div>
        </template>

        <template #status="{ row }">
          <div class="w-full flex justify-end">
            <div
              v-if="showConfirmationActions(row)"
              class="flex justify-center items-center"
            >
              <p class="text-grey-fp-70 mr-10">Did you register?</p>
              <AppButton
                type="warning"
                size="mini"
                class="px-20 mr-10"
                @click="$emit('confirm', row.registrations[0].id)"
              >
                Yes
              </AppButton>
              <AppButton
                type="warning"
                size="mini"
                plain
                class="px-20"
                @click="$emit('discard', row.registrations[0].id)"
              >
                No
              </AppButton>
            </div>
            <AppButton
              v-else
              type="warning"
              size="mini"
              class="px-20"
              @click="$emit('register', row)"
            >
              Register
            </AppButton>
          </div>
        </template>

        <!-- N O  D A T A  S E C T I O N -->
        <template #tableNoItems>
          <div 
            v-if="!$slots.noData" 
            class="flex items-center justify-center py-60 text-grey-fp-70"
          >
            There are no activities
          </div>

          <slot name="noData" />
        </template>
      </AppTable>

      <!-- P A G I N A T I O N -->
      <slot name="pagination" />
    </div>

    <!-- R E G I S T E R   C O N F I R M A T I O N -->
    <portal
      v-if="openModal"
      to="default"
    >
      <AppModal @cancel="$emit('browse')">
        <div class="w-full min-w-280 max-w-400">
          <p class="text-dark-cl-20 text-md mb-8">
            Did you register?
          </p>
          <p class="text-grey-fp-60 mb-40">
            <span>Registration takes place on an external website. Please go to the</span>
            <a
              :href="externalLink"
              target="_blank"
              class="text-primary cursor-pointer"
            > link </a>
            <span>to ensure your attendance is properly registered</span>
          </p>
          <div class="flex items-center justify-end">
            <AppButton
              type="primary"
              size="mini"
              class="px-20 mr-10"
              @click="$emit('confirm')"
            >
              Yes
            </AppButton>
            <AppButton
              size="mini"
              plain
              class="px-20"
              @click="$emit('discard')"
            >
              No
            </AppButton>
            <AppButton
              size="mini"
              plain
              class="px-20"
              @click="$emit('browse')"
            >
              Still browsing
            </AppButton>
          </div>
        </div>
      </AppModal>
    </portal>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';

  import AppTable from '@/components/stateless/AppTable.vue';
  import AppTableCards from '@/components/AppTableCards.vue';
  import AppButton from '@/components/stateless/AppButton.vue';
  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppModal from '@/components/stateless/AppModal.vue';
  import AppTruncatedTooltip from '@/components/stateless/AppTruncatedTooltip.vue';

  import { IActivity } from '@/types';
  import { REGISTRATION_STATUSES, ACTIVITY_STATUSES } from '@/core/activity-statuses';

  export default defineComponent({
    name: 'UpcomingActivities',

    components: { AppTable, AppButton, AppTableCards, AppIcon, AppModal, AppTruncatedTooltip },

    props: {
      activitiesList: {
        type: Array as PropType<IActivity[]>,
        required: true
      },

      headers: {
        type: Array,
        required: true
      },

      title: {
        type: String,
        default: ''
      },

      openModal: {
        type: Boolean,
        default: false
      },

      externalLink: {
        type: String,
        default: ''
      },

      showTypeIcon: {
        type: Boolean,
        default: true
      }
    },

    emits: ['browse', 'register', 'discard', 'confirm'],

    setup() {

      function classChecker(row: IActivity) {
        return row.registrations?.length && row.registrations[0].status === REGISTRATION_STATUSES.INITIAL && 'bg-orange-fp-20';
      }

      function showConfirmationActions({ registrations }: IActivity) {
        return registrations?.length && registrations[0].status === REGISTRATION_STATUSES.INITIAL;
      }

      return {
        REGISTRATION_STATUSES,
        ACTIVITY_STATUSES,

        classChecker,
        showConfirmationActions,
      };
    }

  });
</script>

<style lang="scss" scoped>
  .activities-table {
    max-height: 500px;
  }

  .mobile-table {
    max-height: 600px;
  }
</style>