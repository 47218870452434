
  import { defineComponent } from 'vue';

  import AppIcon from '@/components/stateless/AppIcon.vue';

  export default defineComponent({
    name: 'RegistrationBanner',

    components: { AppIcon },

    emits: ['close']
  });
