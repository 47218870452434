/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ref, reactive, toRefs, readonly } from 'vue';
import { useToast } from "vue-toastification";

import { IActivity, IRequestParams, TIndexedObject, IActivityResponse } from '@/types';

import { activitiesService } from '@/services';
import { vuex } from '@/store';

export const useActivityRegistration = ({ updateActivitiesCb }: any = {}) => {

  const state = reactive({
    registeredActivities: [] as IActivity[],
    activitiesWithoutRegistrations: [] as IActivity[],
  });

  const toast = useToast();
  const openRegisterModal = ref<boolean>(false);
  const openCancellationModal = ref<boolean>(false);
  const modalConfirmationId = ref<number | null>(null);
  const externalLink = ref<string | null>(null);
  const registeredCount = ref<number>(0);
  const withoutRegistrationCount = ref<number>(0);

  const {
    registeredActivities,
    activitiesWithoutRegistrations,
  } = toRefs(state);

  function onCloseModal() {
    openRegisterModal.value = false;
    openCancellationModal.value = false;
    modalConfirmationId.value = null;
    externalLink.value = null;
  }

  function handleBrowsing() {
    onCloseModal();
    updateActivitiesCb();
  }

  async function handleCancellation({ registrations, link }: IActivity) {
    vuex.setLoading(true);

    return activitiesService.cancelRegistration((registrations as TIndexedObject)[0].id)
      .then((res: any) => {
        openCancellationModal.value = true;
        modalConfirmationId.value = res.id;
        externalLink.value = link as string;
        window.open(link, '_blank');
      })
      .catch(({ response }: any) => {
        const { data } = response;
        toast.error(data.message);
        onCloseModal();
      })
      .finally(() => vuex.setLoading(false));
  }

  async function confirmCancellation(tableRowConfirmationId?: number) {
    // Handle confirmation from modal and from button on table row
    const id = modalConfirmationId.value ? modalConfirmationId.value : tableRowConfirmationId;
    vuex.setLoading(true);

    return activitiesService.confirmCancellation(id as number)
      .catch(({ response }: any) => {
        const { data } = response;
        toast.error(data.message);
      })
      .finally(() => {
        onCloseModal();
        vuex.setLoading(false);
        updateActivitiesCb();
      });
  }

  async function discardCancellation(tableRowConfirmationId?: number) {
    // Handle confirmation from modal and from button on table row
    const id = modalConfirmationId.value ? modalConfirmationId.value : tableRowConfirmationId;
    vuex.setLoading(true);

    return activitiesService.discardCancellation(id as number)
      .catch(({ response }: any) => {
        const { data } = response;
        toast.error(data.message);
      })
      .finally(() => {
        onCloseModal();
        vuex.setLoading(false);
        updateActivitiesCb();
      });
  }

  async function confirmRegistration(tableRowConfirmationId?: number) {
    // Handle confirmation from modal and from button on table row
    const id = modalConfirmationId.value ? modalConfirmationId.value : tableRowConfirmationId;
    vuex.setLoading(true);

    return activitiesService.confirmRegistration(id as number)
      .catch(({ response }: any) => {
        const { data } = response;
        toast.error(data.message);
      })
      .finally(() => {
        onCloseModal();
        vuex.setLoading(false);
        updateActivitiesCb();
      });
  }

  async function discardRegistration(tableRowConfirmationId?: number) {
    // Handle confirmation from modal and from button on table row
    const id = modalConfirmationId.value ? modalConfirmationId.value : tableRowConfirmationId;
    vuex.setLoading(true);

    return activitiesService.discardRegistration(id as number)
      .catch(({ response }: any) => {
        const { data } = response;
        toast.error(data.message);
      })
      .finally(() => {
        onCloseModal();
        vuex.setLoading(false);
        updateActivitiesCb();
      });
  }

  async function handleRegistration({ link, id }: Partial<IActivity>) {
    vuex.setLoading(true);

    return activitiesService.registerForActivity(id as number)
      .then((res: any) => {
        openRegisterModal.value = true;
        modalConfirmationId.value = res.id;
        externalLink.value = link as string;
        window.open(link, '_blank');
      })
      .catch(({ response }: any) => {
        const { data } = response;
        toast.error(data.message);
        onCloseModal();
      })
      .finally(() => vuex.setLoading(false));
  }

  async function registerChildForActivity({ link, id }: Partial<IActivity>, childId: any) {
    vuex.setLoading(true);

    return activitiesService.registerChildForActivity(id as number, childId)
      .then((res: any) => {
        openRegisterModal.value = true;
        modalConfirmationId.value = res.id;
        externalLink.value = link as string;
        window.open(link, '_blank');
      })
      .catch(({ response }: any) => {
        const { data } = response;
        toast.error(data.message);
        onCloseModal();
      })
      .finally(() => vuex.setLoading(false));
  }

  async function fetchRegisteredActivities(params?: IRequestParams) {

    return activitiesService.fetchActivities(params)
      .then((res: IActivityResponse) => { 
        registeredActivities.value = res.data; 
        registeredCount.value = res.totalCount;
      })
      .catch(({ response }: any) => {
        const { data } = response;
        toast.error(data.message);
        throw new Error(response);
      });
  }

  async function fetchActivitiesWithoutRegistrations(params?: IRequestParams) {

    return activitiesService.fetchActivities(params)
      .then((res: IActivityResponse) => { 
        activitiesWithoutRegistrations.value = res.data; 
        withoutRegistrationCount.value = res.totalCount;
      })
      .catch(({ response }: any) => {
        const { data } = response;
        toast.error(data.message);
      });
  }

  return {
    externalLink: readonly(externalLink),
    openCancellationModal: readonly(openCancellationModal),
    openRegisterModal: readonly(openRegisterModal),
    registeredActivities: readonly(registeredActivities),
    activitiesWithoutRegistrations: readonly(activitiesWithoutRegistrations),
    withoutRegistrationCount: readonly(withoutRegistrationCount),
    registeredCount: readonly(registeredCount),

    upcomingActivitiesList: readonly(activitiesWithoutRegistrations),
    registeredActivitiesList: readonly(registeredActivities),
    
    handleBrowsing,

    handleCancellation,
    confirmCancellation,
    discardCancellation,

    confirmRegistration,
    discardRegistration,
    handleRegistration,
    registerChildForActivity,

    fetchActivitiesWithoutRegistrations,
    fetchRegisteredActivities
  };
};