export const REGISTRATION_STATUSES = {
  INITIAL: 'initial',
  ACTIVE: 'active',
  CANCELLED: 'cancelled',
  INITIAL_CANCELLED: 'initialCancelled'
};

export const ACTIVITY_STATUSES = {
  ACTIVE: 'active',
  CANCELLED: 'cancelled'
};